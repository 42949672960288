import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/hotsnail-services.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    HotSnail Services
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Nov 11, 2020
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                                    </div>
                                    <div>
                                        <p>HotSnail offers mail forwarding, parcel forwarding, mail scanning, storage and more!</p>
                                        <p>Are you wasting your precious time collecting your mail from the post office or<br />waiting around at home for a package delivery to arrive or worse still, mail is being stolen<br />out of mail boxes with the fear of your identity being sold.</p>
                                        <p>Let Hotsnail take all the stress and worries out of your mail deliveries.<br />Hotsnail is Secure; Convenient and Easy to manage.</p>
                                        <p>You can access your account 24 hours a day / 7 days a week; when its convenient for you.<br />Also you can email us with any enquires regarding your account.</p>
                                        <p>Our mail service offers parcel forwarding, mail forwarding and storage. If you only require a digital copy of your mail, we can shred your mail at your request.</p>
                                        <p>So you can see we are perfect for Private; Business and especially Travellers.</p>
                                        <p>You can access your mail overseas with a click of the mouse.</p>
                                        <p>Get on board to more freedom and flexibility.</p>
                                        <p>Life is too short to be tied to your mail.</p>
                                        <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                                    </div>
                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Services</BadgePost>
                                    <BadgePost>Parcel forwarding</BadgePost>
                                    <BadgePost>Mail Scanning</BadgePost>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
